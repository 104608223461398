import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/ui_rel';

import Ad from '@ifeng-fe/ui_pc_ad';

class ChannelTitle extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const __style = content.style;

        return (
            <div className={style.tit02}>
                <div className={`${style.t_name} ${style[__style]}`}>
                    <span className={style.s01}>
                        <a href={content.titleLink} target="_blank">
                            {content.title[0]}
                        </a>
                    </span>
                    •
                    <span className={style.s02}>
                        <a href={content.url} target="_blank">
                            {content.title[1]}
                        </a>
                    </span>
                </div>

                <Ad content={content.ad} />
            </div>
        );
    }
}

export default errorBoundary(ChannelTitle);
