import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

import { rel } from '@ifeng-fe/ui_rel';

class BrandCard extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };
    state = {};
    changeShow = (target, type) => {
        const result = {};

        result[target] = type;

        this.setState(result);
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const imgDataArray = content.slice(0, 3) || [];
        const listDataArray = content.slice(3, 9) || [];

        const title = item => {
            return (
                <Fragment>
                    <div className={style.textBg} />
                    <div className={style.text}>
                        <a href={item.url} target="_blank" rel={rel}>
                            {item.title}
                        </a>
                    </div>
                </Fragment>
            );
        };
        const imgDom = imgDataArray.map((item, i) => {
            const __class = this.state[item.id] ? style.hoverLi : style.li;

            return (
                <li
                    key={`imgDataa-${i}`}
                    className={__class}
                    onMouseOver={() => this.changeShow(item.id, true)}
                    onMouseOut={() => this.changeShow(item.id, false)}>
                    <div className={style.pic}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <img src={item.thumbnail} title={item.title} alt={item.title} className={style.trans} />
                        </a>
                        {title(item)}
                    </div>
                </li>
            );
        });

        const listDom = listDataArray.map((item, i) => {
            return (
                <li key={`listtxtaa-${i}`}>
                    <a href={item.url} target="_blank" rel={rel}>
                        {item.title}
                    </a>
                </li>
            );
        });

        return (
            <div>
                <div className={style.list07}>
                    <ul>{imgDom}</ul>
                </div>
                <div className={style.list08}>
                    <ul>{listDom}</ul>
                </div>
            </div>
        );
    }
}

export default BrandCard;
