import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/utils';

import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/ui_rel';
import { Share } from '@ifeng-fe/ui_pc_share';

class NewsItems extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    state = {
        data: [],
    };

    insert = (insertArr, replaceArr, adFn) => {
        const { content } = this.props;

        const renderData = content.list || [];

        if (adFn && typeof adFn === 'function') {
            const infoData = [...renderData];

            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        try {
            this.setState({
                data: this.props.content.list,
            });
            // @todo : 模拟广告
            // const ad = {
            //     data: {},
            //     preload: '',
            //     callback: function(data, insert) {
            //         // insert 为我们自定义的广告插入方法
            //         insert([3], function(dom) {
            //             // index 为广告方想要插入的位置，这里也可以改为数组进行批量操作，
            //             // dom 为自定义形式插入的广告位dom，广告方可随意操作
            //         });
            //     },
            // };

            const { ad } = this.props.content;

            const callback = await handleAd(ad);

            callback(ad.data, this.insert);
        } catch (e) {
            console.error(e);
        }
    }
    changeShow = (target, type) => {
        const result = {};

        result[target] = type;

        this.setState(result);
    };

    /**
     * 渲染组件
     */
    render() {
        const { data } = this.state;

        const config = {
            items: ['sina', 'qqzone', 'wechat'],
            display: 'normal',
            hover: 'hover',

            type: 'horizontal',
            methods: 'hover',
            tooltip: 'top',
        };

        const listDom = data.map((item, index) => {
            let result = '';

            if (item.type === 'ad') {
                result = (
                    <div className={style.box} key={index}>
                        <div ref={item.ref} />
                    </div>
                );
            } else {
                const shareInfo = {
                    url: item.oriurl,
                    title: item.title,
                    summery: item.summary,
                    picUrl: `https://${item.thumbnails}`,
                };

                result = (
                    <div className={style.box} key={index}>
                        <div className={style.pic}>
                            <a href={item.url} target="_blank" rel={rel}>
                                <img
                                    src={item.thumbnails}
                                    title={item.title}
                                    alt={item.title}
                                    className={style.trans}
                                />
                            </a>
                        </div>
                        <div className={style.txt}>
                            <h3>
                                <a href={item.url} target="_blank" rel={rel}>
                                    {item.title}
                                </a>
                            </h3>
                            <p>{item.summary}</p>
                            <div className={style.date}>
                                <span>{item.newsTime}</span>
                            </div>
                        </div>

                        <div
                            className={this.state[item.id] ? `${style.fxW} ${style.fxWH}` : style.fxW}
                            ref={item.id}
                            onMouseOver={() => this.changeShow(item.id, true)}
                            onMouseOut={() => this.changeShow(item.id, false)}>
                            <div className={style.fxBtn} />
                            <div className={style.shareBox}>
                                <Share config={config} shareInfo={shareInfo} />
                            </div>
                        </div>
                    </div>
                );
            }

            return result;
        });

        return listDom;
    }
}

export default errorBoundary(NewsItems);
