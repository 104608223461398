import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { getSurveyData, postSurveyData } from '../../../../services/api';
import SurveyTitle from './surveyTitle';
import SurveyContent from './surveyContent';

import { rel } from '@ifeng-fe/ui_rel';

class Survey extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        title: true, // 是否显示标题
        len: 1, // 所展示题目条数， 不传显示所有
        data: {},
        status: 0,
        selectValue: {},
        valueCheck: [],
        resultShow: false,
        tipShow: false,
        isSubmit: false,
    };

    async componentDidMount() {
        try {
            const { content } = this.props;

            const data = await getSurveyData(Number(content.attachmentId));

            if (data.ifsuccess) {
                this.setState({
                    title: content.title,
                    len: content.len,
                    tmpl: content.tmpl,
                    data: data.data,
                    status: data.ifsuccess,
                    resultShow: data.data.surveyinfo.expire === '0',
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    handleShow = () => {
        this.setState({
            tipShow: true,
            resultShow: true,
        });

        this.timer = setTimeout(() => {
            this.setState({ tipShow: false });
        }, 1000);
    };

    handleSubmit = async () => {
        try {
            const { values, entries } = Object;
            const { data, selectValue } = this.state;
            const valueCheck = [];
            const result = data.result;

            result.forEach((item, index) => {
                // 1 必答 0 选答
                const ismust = item.resultArray.ismust;

                if (ismust) {
                    if (values(selectValue).length === 0) {
                        valueCheck.push(index);
                    }
                    for (const v of values(selectValue)) {
                        if (v === '' || v.length === 0) {
                            valueCheck.push(index);
                        }
                    }
                }
            });

            this.setState({ valueCheck });

            if (valueCheck.length === 0) {
                const { content } = this.props;
                const value = [];

                for (const [k, v] of entries(selectValue)) {
                    if (k && v) {
                        if (typeof v === 'string') {
                            value.push(`sur[${k}][]=${v}`);
                        } else {
                            v.forEach(item => {
                                value.push(`sur[${k}][]=${item}`);
                            });
                        }
                    }
                }

                const survey = await postSurveyData(value, content.id);

                this.setState({
                    resultShow: true,
                });

                if (survey.ifsuccess) {
                    this.setState({
                        isSubmit: true,
                    });
                }
                if (typeof window !== 'undefined') {
                    window.alert(survey.msg);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const { data, status, isSubmit, title, len, tmpl } = this.state;

        return (
            <div className={styles.research}>
                {status === 1 ? (
                    <div className={styles.survey_box}>
                        {title ? (
                            <SurveyTitle
                                expire={data.surveyinfo.expire}
                                pnum={data.surveyinfo.pnum}
                                channelurl={data.surveyinfo.channelurl}
                                id={data.surveyinfo.id}
                                title={data.surveyinfo.title}
                            />
                        ) : null}
                        <div className={styles.survey_content}>
                            <SurveyContent len={len} data={data} tmpl={tmpl} id={data.surveyinfo.id} />
                            {data.surveyinfo.expire === '0' ? (
                                <div className={styles.voteEnd}>
                                    <h4>
                                        <a
                                            href={`//fashion.ifeng.com/survey.html#id=${data.surveyinfo.id}`}
                                            target="_blank"
                                            rel={rel}>
                                            调查已结束，点击查看结果
                                        </a>
                                    </h4>
                                </div>
                            ) : (
                                <div className={styles.vote}>
                                    <h4>
                                        <a
                                            href={`//fashion.ifeng.com/survey.html#id=${data.surveyinfo.id}`}
                                            target="_blank"
                                            rel={rel}>
                                            我也来投一票
                                        </a>
                                    </h4>
                                    <p>
                                        <span id="lblSurveyCount">{data.surveyinfo.pnum}</span>人参与投票
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export { Survey };
export default Survey;
