import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

class Static extends React.PureComponent {
    static propTypes = {
        content: PropTypes.string,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }
}

export default Static;
