import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import LeftContent from './leftContent';
import RightContent from './rightContent';

class S03 extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return (
            <div className={style.wrap}>
                <LeftContent content={content.left} />

                <RightContent content={content.right} />
            </div>
        );
    }
}

export default S03;
