import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import Chip from 'Chip';

import errorBoundary from '@ifeng-fe/errorBoundary';

import Slides from '@ifeng-fe/ui_pc_slides';

import { rel } from '@ifeng-fe/ui_rel';

import SliderTmpl from './sliderTmpl';

class S02 extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    menuInfo = {
        stars: this.props.content.menuInfo.stars,
        makeup: this.props.content.menuInfo.makeup,
        hours: this.props.content.menuInfo.hours,
        beauty: this.props.content.menuInfo.beauty,
        newFashion: this.props.content.menuInfo.newFashion,
        fashionStuff: this.props.content.menuInfo.fashionStuff,
        double: this.props.content.menuInfo.double
    };
    
    state = {
        current: 0,
        data: [],
    };

    getRenderData = () => {
        const { content } = this.props;
        let result = [];

        let menuArray = Object.keys(content);

        menuArray = menuArray && menuArray.slice(0, 7);
        result = menuArray.map(item => {
            return { data: content[item], ...this.menuInfo[item] };
        });

        return result;
    };

    /**
     * 渲染组件
     */
    render() {
        const { current } = this.state;

        const renderData = this.getRenderData();

        const sliderTmpl = (item) => {
            const fragementData = item.data;

            // const info = { columnLink: item.columnLink };

            return (
                <Chip
                    id={item.chipID}
                    type="struct"
                    title={`独家策划${item.chipTitle}`}
                    groupName="第二个通栏"
                    translate={'handles02SliderData'}
                    content={fragementData}>
                    <SliderTmpl />
                </Chip>
            );
        };

        const dotTmpl = function dotTmpl(item, index) {
            return (
                <div key={`sliderdot-${index}`} className={style.li}>
                    <a href={item.columnLink} target="_blank" rel={rel} sign-trs-href={item.columnLink}>
                        {renderData[index].chipTitle}
                    </a>
                </div>
            );
        };

        const config = {
            autoplay: true,
            arrows: false,
            dots: true,
            axis: 'horizonta',
            dotStyle: style.dot,
            dotCurrentStyle: style.current,
            sliderTmpl,
            dotTmpl,
            speed: 0,
            dotsAction: 'hover',
            autoplayInterval: 5000,
        };

        const slider = (
            <div className={style.scroll2}>
                {renderData.length > 0 ? <Slides content={renderData} config={config} /> : null}
            </div>
        );

        return (
            <div className={style.wrap}>
                <Chip
                    id="260190"
                    type="struct"
                    title="独家策划副导航"
                    groupName="独家策划">
                    <div className={style.tit01}>
                        <div className={style.t_name}>
                            <span className={style.s01}>独家</span>
                            <span className={style.s02}>策划</span>
                        </div>
                    </div>
                </Chip>
                {slider}
            </div>
        );
    }
}
export default errorBoundary(S02);
