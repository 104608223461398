import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class SurveyTitle extends React.PureComponent {
    static propTypes = {
        expire: PropTypes.string,
        pnum: PropTypes.number,
        channelurl: PropTypes.string,
        id: PropTypes.string,
        title: PropTypes.string,
    };

    render() {
        const { expire, pnum, channelurl, id, title } = this.props;

        return (
            <div className={styles.survey_title}>
                <div className={styles.count_box}>
                    <span className={styles.status}>{expire === '0' ? '已结束' : '进行中'}</span>
                    <span className={styles.count}>{pnum}</span>人参与
                </div>
                <div className={styles.logo}>
                    <span className={styles.img} />
                    <span>凤凰公测</span>
                </div>
                <h2>
                    <a href={`${channelurl}survey.html#id=${id}`} target="_blank" rel={rel}>
                        {title}
                    </a>
                </h2>
            </div>
        );
    }
}

export default SurveyTitle;
