import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import { rel } from '@ifeng-fe/ui_rel';

class Tv extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const firstData = content.slice(0, 1) || [];
        const secData = content.slice(1, 2) || [];
        const listData = content.slice(2, 4) || [];

        const firstDom = firstData.map((item, i) => {
            return (
                <Fragment key={i}>
                    <div className={style.videoBtn2}>
                        <a href={item.url} target="_blank" rel={rel}>
                            &nbsp;
                        </a>
                    </div>
                    <div className={style.videoZz2}>
                        <a href="#" target="_blank" rel={rel}>
                            &nbsp;
                        </a>
                    </div>
                    <div className={style.pic}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <img src={item.thumbnail} title={item.title} alt={item.title} />
                        </a>
                    </div>
                </Fragment>
            );
        });

        const secDom = secData.map((item, i) => (
            <p key={`sec-${i}`}>
                <a href={item.url} target="_blank" rel={rel}>
                    {item.title}
                </a>
            </p>
        ));

        const listDom = listData.map((item, i) => {
            return (
                <li key={`list-${i}`}>
                    <a href={item.url} target="_blank" rel={rel}>
                        {item.title}
                    </a>
                </li>
            );
        });

        return (
            <div className={style.list12}>
                <div className={style.pt12}>
                    {firstDom}
                    {secDom}
                </div>
                <ul>{listDom}</ul>
            </div>
        );
    }
}

export default Tv;
