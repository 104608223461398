import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import Chip from 'Chip';
import Slider from './slider';
import ImgCardWithChannel from '../../../../components/imgCardWithChannel';
import Dress from './dress';
import Video from './video';

const logoArr = [
    '//p0.ifengimg.com/fe/fashion_ifeng_com/images/t1_28bba4b7.png', // 美容
    '//p0.ifengimg.com/fe/fashion_ifeng_com/images/t2_447850e6.png', // 奢华
    '//p0.ifengimg.com/fe/fashion_ifeng_com/images/t3_41c038bf.png', // 情感
    '//p0.ifengimg.com/fe/fashion_ifeng_com/images/t4_6e0551c9.png ', // 情感
];

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return (
            <div className={style.col}>
                <Chip
                    key="chip-55022"
                    id="55022"
                    type="recommend"
                    title="首屏轮播图推荐位"
                    groupName="第一个通栏"
                    translate={'handleS01LeftSliderData'}
                    content={content.slider.sliderRec}>
                    <Slider ad={content.slider.ad} />
                </Chip>
                <div className={`${style.mt36} clearfix`}>
                    <div className={style.left}>
                        <ImgCardWithChannel
                            content={{
                                ...content.beauty,
                                logo: logoArr[0],
                            }}
                        />
                        <ImgCardWithChannel
                            content={{
                                ...content.luxury,
                                logo: logoArr[1],
                            }}
                        />
                        <ImgCardWithChannel
                            content={{
                                ...content.emotion,
                                logo: logoArr[2],
                            }}
                        />
                    </div>
                    <div className={style.right}>
                        <Chip
                            key="chip-55025"
                            id="55025"
                            type="recommend"
                            title="首屏时装推荐位"
                            groupName="第一个通栏"
                            translate={'handles01column'}
                            content={content.dress}>
                            <Dress info={{ logo: logoArr[3], ad: [content.dressAd1, content.dressAd2] }} />
                        </Chip>

                        <Video content={{ data: content.video, ad: content.videoAd }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;
