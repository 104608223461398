import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/ui_rel';

class ChannelTitle extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return (
            <div className={style.titR}>
                <span>
                    <a href={content.link} target="_blank" rel={rel}>
                        more
                    </a>
                </span>
                <strong>{content.title}</strong>
            </div>
        );
    }
}

export default errorBoundary(ChannelTitle);
