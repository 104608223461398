import { jsonp, ajax } from '@ifeng-fe/ui_base';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */

if (typeof window !== 'undefined' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

const md5 = require('md5');

const createJsonpCallbackName = (str, num) => {
    num = num ? num : 0;
    let jsonpCallbackName = `_${md5(`${str}_${num}`)}`;

    /* eslint-disable */
    while (typeof window === 'object' && window[jsonpCallbackName]) {
        /* eslint-enable */
        num++;
        jsonpCallbackName = `_${md5(`${str}_${num}`)}`;
    }

    return jsonpCallbackName;
};

// 获取调查数据
export const getSurveyData = async (id, index = 0) => {
    const { schemaCheck } = await import('./validate');

    let data = await jsonp('//sv.ifeng.com/index.php/survey/getdata', {
        data: {
            id,
        },
        jsonpCallback: createJsonpCallbackName(id, index),
        cache: false,
    });
    const requestUrl = `sv.ifeng.com/index.php/survey/getdata?id=${id}&callback=getSurveyData`;

    data = schemaCheck(data, 'surveyDataSchema', requestUrl);

    return data ? data : null;
};

/**
 * 提交调查
 * @param {String} id 调查id
 * @param {String} sur 选项拼接字符串
 */
export const postSurveyData = async (id, sur) => {
    try {
        const data = await jsonp(`//sv.ifeng.com/index.php/survey/postsurvey?${sur}`, {
            data: { id },
            jsonpCallback: 'callback',
            cache: false,
        });

        return data;
    } catch (e) {
        console.error(e);
    }
};
