import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/ui_rel';

class ImgCardWithChannel extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    state = {
        hover: false,
    };

    getShort = data => {
        const __summaryShort =
            data.summary && data.summary.length > 20 ? `${data.summary.substring(0, 20)}...` : data.summary;
        const __titleShort = data.title && data.title.length > 16 ? `${data.title.substring(0, 16)}...` : data.title;

        return {
            ...data,
            summaryShort: __summaryShort,
            titleShort: __titleShort,
        };
    };

    changeStyle = boolean => {
        this.setState({
            hover: boolean,
        });
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const { hover } = this.state;
        // {
        //     logo: 'http://d.ifengimg.com/w310_h232/p0.ifengimg.com/a/2018_43/e4aca08fc0e1202.jpg',
        //     title: '',
        //     summary: '',
        //     pic: '',
        //     url: '',
        //     summaryShort: '',
        //     titleShort: '',
        // }

        const item = this.getShort(content);

        return (
            <div
                className={hover ? `${styles.li} ${styles.hoverBg}` : styles.li}
                onMouseOver={() => this.changeStyle(true)}
                onMouseOut={() => this.changeStyle(false)}>
                <a href={item.url} target="_blank" rel={rel}>
                    <img src={item.pic} title={item.title} alt={item.title} />
                </a>
                <div className={styles.t0}>
                    <a href={item.url} target="_blank" rel={rel}>
                        <img src={item.logo} title={item.title} alt={item.title} />
                    </a>
                </div>
                <div className={styles.text}>
                    <a className={styles.textA} href={item.url} target="_blank" rel={rel}>
                        <h2 className={hover ? styles.hoverTitleH2 : styles.titleH2} title={item.title}>
                            {item.title}
                        </h2>
                        <p className={hover ? styles.hoverLine : styles.line} title={item.summary}>
                            {item.summary}
                        </p>
                    </a>
                </div>
                <div className={styles.textBg} />
            </div>
        );
    }
}

export default errorBoundary(ImgCardWithChannel);
