import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import style from './index.css';

import TxtTitle from '../../../../../components/txtTitle';
import Slider from './slider';

class Skin extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const config = {
            title: content.title,
            link: content.link,
        };

        return (
            <div className={style.wrap}>
                <TxtTitle content={config} />
                <Chip
                    id="55036"
                    type="recommend"
                    title="专家肌诊室"
                    groupName="第三个通栏"
                    translate={'handleS03RightSkin'}
                    content={content.data}>
                    <Slider />
                </Chip>
            </div>
        );
    }
}

export default Skin;
