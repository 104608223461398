import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/utils';

import errorBoundary from '@ifeng-fe/errorBoundary';

import Slides from '@ifeng-fe/ui_pc_slides';

import { rel } from '@ifeng-fe/ui_rel';

class BigPic extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        info: PropTypes.object,
    };
    state = {
        data: this.props.content,
    };

    insert = (insertArr, replaceArr, adFn) => {
        const { content } = this.props;

        const renderData = [...content];

        if (adFn && typeof adFn === 'function') {
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                renderData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                renderData.splice(item, 1, { type: 'ad', ref });
            });

            this.setState(
                {
                    data: renderData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        try {
            this.setState({
                data: this.props.content,
            });

            // @todo : 模拟广告
            // const ad = {
            //     data: {},
            //     preload: '',
            //     callback:
            //         'function(data,insert){console.log(1,data,insert);var box1=document.createElement("div");IfengAmgr.show(box1,data,function(){insert([1],[],function(dom){dom[1].appendChild(box1)})});}',
            // };

            const { info } = this.props;

            // info.ad = {
            //     data: {},
            //     preload: '',
            //     callback:
            //         'function(data,insert){console.log(data,insert,"222");var box1=document.createElement("div"); insert([1],[],function(dom){dom[1].appendChild(box1)})}',
            // };

            const callback = await handleAd(info.ad);

            callback(info.ad.data, this.insert);
        } catch (e) {
            console.error(e);
        }
    }

    // getRenderData = () => {
    //     const { content } = this.props;
    //     const { data } = this.state;

    //     const result = content || [];

    //     const adArr = data.filter(item => item.type === 'ad');

    //     if (adArr.length !== 0) {
    //         result[1] = data[1];
    //     }

    //     return result;
    // };

    /**
     * 渲染组件
     */
    render() {
        const { data } = this.state;

        const sliderTmpl = item => {
            return item.type && item.type === 'ad' ? (
                <div className={style.li}>
                    <div ref={item.ref} />
                </div>
            ) : (
                <div className={style.pt6}>
                    <div className={style.pic}>
                        <a href={item.url} target="_blank" rel={rel} data-nomask>
                            <img src={item.pic} title="明星" alt="明星" />
                        </a>
                    </div>
                    <div className={style.kuang}>
                        <a href={item.url} target="_blank" rel={rel} data-nomask>
                            &nbsp;
                        </a>
                    </div>
                    <div className={style.textBg} />
                    <div className={style.text}>
                        <h2>
                            <a href={item.url} target="_blank" rel={rel} title={item.title} data-innermask>
                                {item.title}
                            </a>
                        </h2>

                        <p title={item.summary}>{item.summary}</p>
                    </div>
                </div>
            );
        };

        const config = {
            autoplay: true,
            arrows: false,
            dots: true,
            axis: 'horizonta',
            dotStyle: style.dot,
            dotCurrentStyle: style.current,
            sliderTmpl,
            speed: 0,
            dotsAction: 'hover',
        };

        const dom = (
            <div className={style.scroll6}>{data.length > 0 ? <Slides content={data} config={config} /> : null}</div>
        );

        return dom;
    }
}
export default errorBoundary(BigPic);
