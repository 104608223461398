import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import style from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import { addEventListener } from '@ifeng-fe/ui_base';

import Ad from '@ifeng-fe/ui_pc_ad';
import Header from './header';
import Navigation from './navigation';
import Content from './content';
import Footer from './footer';
import Cooperation from './cooperation';
import BottomAffix from './bottomAffix';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
        this.unHandleClick = addEventListener(document, 'click', this.handleClick);
    }

    componentWillUnmount() {
        this.unHandleClick();
    }

    // 全站渠道标记传递，文章页的任意跳转
    handleClick = e => {
        const tag = this.getATag(e.target);

        if (!tag) return;

        let localHref =
            (tag.attributes['sign-trs-href'] ? tag.attributes['sign-trs-href'].value : '') ||
            (tag.attributes['href'] ? tag.attributes['href'].value : '');

        if (
            localHref &&
            localHref !== 'undefined' &&
            localHref !== '' &&
            localHref !== '#' &&
            localHref.indexOf('javascript:') === -1
        ) {
            localHref = localHref.trim();
            const localSearch = location.search;
            const localHash = location.hash;

            tag.setAttribute('sign-trs-href', localHref);

            let newUrl = '';
            let inWhitelist = false;
            const whitelist = ['//dol.deliver.ifeng.com/'];

            for (let i = 0, len = whitelist.length; i < len; i++) {
                if (localHref.indexOf(whitelist[i]) > -1) {
                    inWhitelist = true;
                }
            }
            // 传递下划线开头的统计
            const curSrc = this.getParam(localSearch, localHash);

            if ((localSearch || localHash) && curSrc && !inWhitelist) {
                if (localHref.indexOf('?') > -1) {
                    newUrl = `${localHref}&${curSrc}`;
                } else {
                    newUrl = `${localHref}?${curSrc}`;
                }

                tag.setAttribute('href', newUrl);
            }
        }
    };

    getATag = tag => {
        if (!tag) {
            return null;
        }

        if (tag.tagName !== 'A') {
            return this.getATag(tag.parentElement);
        } else {
            return tag;
        }
    };

    getSign = (localSearch, ret) => {
        const localSearchArr = localSearch.split('#');

        for (let i = 0; i < localSearchArr.length; i++) {
            const localParam = localSearchArr[i];

            if (localParam.indexOf('_') === -1) continue;

            const localParamArr = localParam.split('?');

            for (let j = 0; j < localParamArr.length; j++) {
                const localParam2 = localParamArr[j];

                if (localParam2.indexOf('_') === -1) continue;

                const localParam2Arr = localParam2.split('&');

                for (let m = 0; m < localParam2Arr.length; m++) {
                    const localParam3 = localParam2Arr[m];

                    if (localParam3.indexOf('_') === -1) continue;

                    const localParam3Arr = localParam3.split('|');

                    for (let k = 0; k < localParam3Arr.length; k++) {
                        const localParam4 = localParam3Arr[k];

                        if (localParam4.indexOf('_') !== 0) continue;

                        if (ret === '') {
                            ret += localParam4;
                        } else {
                            ret = `${ret}&${localParam4}`;
                        }
                    }
                }
            }
        }

        return ret;
    };

    getParam = (localSearch, localHash) => {
        let ret = '';

        if (localSearch.indexOf('_zbs') > -1) {
            ret = this.getSign(localSearch, ret);
        }
        if (localHash.indexOf('_zbs') > -1) {
            ret = this.getSign(localHash, ret);
        }

        return ret;
    };

    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const headerData = {
            nav: content.nav,
            search: content.search,
            topAd: content.topAd,
            logo: content.logo,
            channelAd: content.channelAd,
        };
        const contentData = {
            s01: {
                left: {
                    slider: {
                        sliderRec: content.s01sliderRec,
                        ad: content.s01SliderAd,
                    },
                    beauty: content.s01columnBeautify,
                    luxury: content.s01columnLuxury,
                    emotion: content.s01columnEmotion,
                    dress: content.s01columnDress,
                    dressAd1: content.s01columnDressAd1,
                    dressAd2: content.s01columnDressAd2,
                    video: content.s01columnVideo,
                    videoAd: content.s01columnVideoAd,
                },
                right: {
                    bigPic: content.s01rightBigPic,
                    bigPicAd: content.s01rightBigPicAd,
                    txtPic: content.s01rightTxtPic,
                    txtPicTags: content.s01rightTxtPicTags,
                    adData: content.s01rightAdData,
                },
            },
            s02: {
                stars: content.s02stars,
                makeup: content.s02makeup,
                hours: content.s02hours,
                beauty: content.s02beauty,
                newFashion: content.s02newFashion,
                fashionStuff: content.s02fashionStuff,
                double: content.s02double,
                menuInfo: content.menuInfo
            },
            middleAd: content.middleAd,
            s03: {
                left: {
                    fashion: {
                        titleAd: content.s03LeftFashionTitleAd,
                        threePic: content.s03LeftFashionF01,
                        list: content.s03LeftFashionF02,
                        listAd: content.s03LeftFashionListAd,
                        ad: content.s03LeftFashionAd,
                    },
                    beauty: {
                        titleAd: content.s03LeftBeautyTitleAd,
                        threePic: content.s03LeftBeautyF01,
                        list: content.s03LeftBeautyF02,
                        listAd: content.s03LeftBeautyListAd,
                        ad: content.s03LeftBeautyAd,
                    },
                    jewelry: {
                        titleAd: content.s03LeftJewelryTitleAd,
                        threePic: content.s03LeftJewelryF01,
                        list: content.s03LeftJewelryF02,
                        listAd: content.s03LeftJewelryListAd,
                        ad: content.s03LeftJewelryAd,
                    },
                    emotion: {
                        titleAd: content.s03LeftEmotionTitleAd,
                        list: content.s03LeftEmotion,
                        listAd: content.s03LeftEmotionListAd,
                        ad: content.s03LeftEmotionAd,
                    },
                    brand: {
                        list: content.s03LeftBrand,
                        titleAd: content.s03LeftBrandTitleAd,
                    },
                },
                right: {
                    ad01: content.s03RightAd01,

                    dress: content.s03RightDress,
                    fashionWeek: content.s03RightFashionWeek,

                    ad02: content.s03RightAd02,

                    newProduct: content.s03RightNewProduct,

                    ad03: content.s03RightAd03,

                    skin: content.s03RightSkin,
                    productEvaluation: content.s03RightProductEvaluation,
                    tv: content.s03RightTv,
                    luxury: content.s03RightLuxury,
                    survey: content.s03RightSurvey,
                },
            },
            s03UnderAd: content.s03UnderAd,
            s04: content.s04Pic,
            s04UnderAd: content.s04UnderAd,
        };
        const footerData = {
            bottomAd: content.bottomAd,
            footer: content.footer,
        };
        const cooperation = content.cooperation;

        const bottomAffixData = {
            floatAd1: content.floatAd1,
            floatAd2: content.floatAd2,
            floatAd3: content.floatAd3,
            floatAd4: content.floatAd4,
            floatAd5: content.floatAd5,
        };

        console.log('content==', content);

        return (
            <div>
                <Header content={headerData} />
                <Chip
                    id="30045"
                    type="struct"
                    title="时尚导航"
                    groupName="导航栏"
                    position="relative"
                    content={content.navigation}>
                    <Navigation adInfo={content.navigationAd} />
                </Chip>
                <Content content={contentData} />
                <NoSSR>
                    <Chip
                        id="25012"
                        type="static"
                        title="时尚首页底部合作链接"
                        groupName="底部合作链接"
                        position="relative"
                        content={cooperation}>
                        <Cooperation />
                    </Chip>
                </NoSSR>
                <Footer content={footerData} />
                <NoSSR>
                    <BottomAffix content={bottomAffixData} />
                </NoSSR>
                <Ad content={content.popunderAd} />

                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default Layout;
