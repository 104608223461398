import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

import TxtTitle from '../../../../../components/txtTitle';
import Survey from '../../../../../components/survey';

class SurveyBlock extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        config: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content, config } = this.props;

        const __config = {
            title: config.title,
            link: config.link,
        };

        return (
            <div className={style.wrap}>
                <TxtTitle content={__config} />

                <Survey content={{ attachmentId: content.surveyId, title: false, len: 1 }} />
            </div>
        );
    }
}

export default SurveyBlock;
