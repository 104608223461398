import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

import TxtTitle from '../../../../../components/txtTitle';
import Slider from './slider';

class Luxury extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const config = {
            title: content.title,
            link: content.link,
        };

        return (
            <div className={style.wrap}>
                <TxtTitle content={config} />

                <Slider content={content.data} />
            </div>
        );
    }
}

export default Luxury;
