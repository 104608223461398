import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

import Chip from 'Chip';
import VideoDom from './videoDom';

import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/utils';

// import Ad from '@ifeng-fe/ui_pc_ad';

class Video extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return (
            <Chip key="chip-225720" id="225720" type="recommend" title="首页视频" groupName="第一个通栏" translate="handles01columnVideo" content={content}>
                {/* {data && data.guid ? */}
                <VideoDom />
                {/* : <div ref={this.box}>Loading...</div>} */}
            </Chip>
        );
    }
}

export default Video;
