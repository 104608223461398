import React from 'react';
import PropTypes from 'prop-types';

import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';

import style from './index.css';

import Dress from './dress';
import FashionWeek from './fashionWeek';
import NewProduct from './newProduct';
import Skin from './skin';
import ProductEvaluation from './productEvaluation';
import Tv from './tv';
import Luxury from './luxury';
import SurveyBlock from './surveyBlock';

const config = {
    fashionWeek: {
        title: '时装周',
        link: '//fashion.ifeng.com/trends/special/2018parismen/',
    },
    newProduct: {
        title: '新品试用',
        link: ' //try.cosmetics.ifeng.com/',
    },
    skin: {
        title: '专家肌诊室',
        link: ' //fashion.ifeng.com/beauty/',
    },
    productEvaluation: {
        title: '产品评测',
        link: ' //fashion.ifeng.com/beauty/special/shiyongzhongxin/',
    },
    luxury: {
        title: '凤凰奢享家',
        link: '//fashion.ifeng.com/lifestyle/',
    },
    survey: {
        title: '热门调查',
        link: ' //fashion.ifeng.com/',
    },
};

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    getData = key => {
        const { content } = this.props;

        const ori = content[key] || {};
        const con = config[key] || {};

        return {
            data: ori,
            ...con,
        };
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const fashionWeekData = this.getData('fashionWeek');
        const skinData = this.getData('skin');
        const productEvaluationData = this.getData('productEvaluation');
        const luxuryData = this.getData('luxury');
        const surveyData = this.getData('survey');
        const newProductData = this.getData('newProduct');

        return (
            <div className={style.col}>
                <Ad content={content.ad01} />
                <Dress content={content.dress} />

                <FashionWeek content={fashionWeekData} />
                <div className={style.mt50}>
                    <Ad content={content.ad02} />
                </div>
                <NewProduct content={newProductData} />
                <div className={style.mt36}>
                    <Ad content={content.ad03} />
                </div>
                <Skin content={skinData} />

                <ProductEvaluation content={productEvaluationData} />

                <Chip
                    id="55027"
                    type="recommend"
                    title="爱美TV"
                    groupName="第三个通栏"
                    translate={'handleS03RightTv'}
                    content={content.tv}>
                    <Tv />
                </Chip>

                <Luxury content={luxuryData} />

                <Chip id="30048" type="struct" title="热门调查" groupName="第三个通栏" content={content.survey}>
                    <SurveyBlock config={surveyData} />
                </Chip>
            </div>
        );
    }
}

export default Content;
