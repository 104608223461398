import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import LeftContent from './leftContent';
import RightContent from './rightContent';

class S01 extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return (
            <div className={`${style.col_w1000}  clearfix`}>
                <LeftContent content={content.left} />

                <RightContent content={content.right} />
            </div>
        );
    }
}

export default S01;
