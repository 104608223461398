import React from 'react';
import PropTypes from 'prop-types';

import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';

import style from './index.css';

import BigPic from './bigPic';
import TxtPic from './txtPic';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return (
            <div className={style.col}>
                <Chip
                    key="chip-55023"
                    id="55023"
                    type="recommend"
                    title="首屏右侧-上部分推荐位"
                    groupName="第一个通栏"
                    translate={'handles01columnBigPic'}
                    content={content.bigPic}>
                    <BigPic info={{ ad: content.bigPicAd }} />
                </Chip>
                <div style={{ display: 'none' }}>
                    <Chip
                        key="chip-45040"
                        id="45040"
                        type="struct"
                        title="首屏右侧-下部分推荐位标签"
                        groupName="第一个通栏"
                        content={content.txtPicTags}>
                        <div />
                    </Chip>
                </div>
                <Chip
                    key="chip-55024"
                    id="55024"
                    type="recommend"
                    title="首屏右侧-下部分推荐位"
                    groupName="第一个通栏"
                    translate={'handles01columnTxtPic'}
                    content={content.txtPic}>
                    <TxtPic tagConfig={content.txtPicTags} />
                </Chip>
                <div className={style.list04}>
                    <Ad content={content.adData} />
                </div>
            </div>
        );
    }
}

export default Content;
