import React, { Fragement } from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

import errorBoundary from '@ifeng-fe/errorBoundary';

import { rel } from '@ifeng-fe/ui_rel';

class SliderTmpl extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const column3 = content.length > 3;
        const firstGroupData = column3 ? content.slice(0, 2) : [];
        const middleGroupData = column3 ? content.slice(2, 3)[0] : content.slice(0, 1)[0];
        const rightGroupData = column3 ? content.slice(3, 5) : content.slice(1, 3);

        /**
         * 5条数据是一个样式
         * 3条数据是另一个样式
         */

        const leftColumn = firstGroupData.map((item, index) => (
            <li key={`firstg-${index}`}>
                <span>
                    <a href={item.url} target="_blank" rel={rel}>
                        {item.subTitle}
                    </a>
                </span>
                <div className={style.pic}>
                    <a href={item.url} target="_blank" rel={rel}>
                        <img src={item.thumbnail} title={item.title} alt={item.title} className={style.trans} />
                    </a>
                </div>
                <p>
                    <a href={item.url} target="_blank" rel={rel}>
                        {item.title}
                    </a>
                </p>
            </li>
        ));

        const middleColumn = item => {
            return (
                <div className={column3 ? style.zhPic : `${style.zhPic} ${style.zhPicW} `}>
                    <div className={style.pic}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <img src={item.thumbnail} title={item.title} alt={item.title} className={style.trans} />
                        </a>
                    </div>
                    <div className={style.name}>
                        <a href={item.url} target="_blank" rel={rel}>
                            {item.topicTitle}
                        </a>
                    </div>
                    <span>
                        <a href={item.url} target="_blank" rel={rel}>
                            {item.subTitle}
                        </a>
                    </span>
                    <h3>
                        <a href={item.url} target="_blank" rel={rel}>
                            {item.title}
                        </a>
                    </h3>
                </div>
            );
        };

        const rightColumn = rightGroupData.map((item, index) => (
            <li key={`rightgroup-${index}`}>
                <span>
                    <a href={item.url} target="_blank" rel={rel}>
                        {item.subTitle}
                    </a>
                </span>
                <div className={style.pic}>
                    <a href={item.url} target="_blank" rel={rel}>
                        <img src={item.thumbnail} title={item.title} alt={item.title} className={style.trans} />
                    </a>
                </div>
                <p>
                    <a href={item.url} target="_blank" rel={rel}>
                        {item.title}
                    </a>
                </p>
            </li>
        ));

        return (
            <div className={style.pt2}>
                {column3 ? <ul className={style.list05}>{leftColumn}</ul> : null}

                {middleColumn(middleGroupData)}

                <ul className={style.list05}>{rightColumn}</ul>
            </div>
        );
    }
}
export default errorBoundary(SliderTmpl);
