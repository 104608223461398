import React from 'react';
import PropTypes from 'prop-types';

import { rel } from '@ifeng-fe/ui_rel';
import style from './index.css';

class List extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const listDom = content.map((item, i) => {
            return (
                <li className={style.item} key={`pelist-${i}`}>
                    <div className={style.pic}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <img src={item.thumbnails} title={item.title} alt={item.title} className={style.trans} />
                        </a>
                    </div>
                    <div className={style.txt}>
                        <h4>
                            <a href={item.url} target="_blank" rel={rel}>
                                {item.title}
                            </a>
                        </h4>
                        <p>{item.summary}</p>
                    </div>
                </li>
            );
        });

        return <ul className={style.list11}>{listDom}</ul>;
    }
}

export default List;
