import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { rel } from '@ifeng-fe/ui_rel';

const bg = [
    '//p2.ifengimg.com/9db17ab793eef980/2016/0423/per_6.gif',
    '//p2.ifengimg.com/9db17ab793eef980/2016/0423/per_7.gif',
    '//p2.ifengimg.com/9db17ab793eef980/2016/0423/per_8.gif',
    '//p2.ifengimg.com/9db17ab793eef980/2016/0423/per_9.gif',
];

const prefix = ['A', 'B', 'C', 'D'];

class SurveyContent extends React.PureComponent {
    static propTypes = {
        data: PropTypes.object,
        len: PropTypes.number,
        tmpl: PropTypes.object,
        id: PropTypes.string,
    };

    render() {
        const { data, len, tmpl, id } = this.props;

        const __result = data.result;
        const list = [];

        const result = len ? __result.splice(0, len) : __result;

        result.forEach((item, index) => {
            const options = (
                <div key={index} className={styles.item}>
                    <h3 className={styles.cPink}>
                        <a href={`//fashion.ifeng.com/survey.html#id=${id}`} target="_blank" rel={rel}>
                            {item.resultArray.question}
                        </a>
                    </h3>
                    <ul className={styles.item02}>
                        {item.resultArray.option.map((item, index) => (
                            <li key={index}>
                                <span className={styles.num}>{prefix[index]}</span>
                                <span>
                                    <a>
                                        <img src={bg[index]} width={item.nump} height="12" />
                                    </a>
                                </span>
                                <span className={styles.per}>{item.nump}%</span>
                                <p>的网友表示{item.title}。</p>
                            </li>
                        ))}
                    </ul>
                </div>
            );
            const html = tmpl ? tmpl(item, index) : options;

            list.push(html);
        });

        return <div className={styles.item02}>{list}</div>;
    }
}

export default SurveyContent;
