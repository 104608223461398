import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/ui_rel';

class ThreePics extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const __data = content || [];
        /**
         * @desc content
         * url ‘’
         * thumbnails.image [0,1,2]
         * title  ‘’
         * newsTime '2018-10-23 12:09'
         */

        const list = __data.map((item, index) => {
            return (
                <div className={style.box} key={`newlist${index}`}>
                    <div>
                        <h3>
                            <a href={item.url} target="_blank" rel={rel}>
                                {item.title}
                            </a>
                            <img src="http://p0.ifengimg.com/fe/fashion_ifeng_com/images/hot_1dc2f8ca.png" />
                        </h3>
                        <div className={style.imgList}>
                            <ul>
                                <li>
                                    <a href={item.url} target="_blank" rel={rel}>
                                        <img src={item.thumbnails[0]} className={style.trans} />
                                    </a>
                                </li>
                                <li>
                                    <a href={item.url} target="_blank" rel={rel}>
                                        <img src={item.thumbnails[1]} className={style.trans} />
                                    </a>
                                </li>
                                <li className={style.lbn}>
                                    <a href={item.url} target="_blank" rel={rel}>
                                        <img src={item.thumbnails[2]} className={style.trans} />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className={style.date}>
                            <span>{item.newsTime}</span>
                        </div>
                    </div>
                </div>
            );
        });

        return list;
    }
}

export default errorBoundary(ThreePics);
