import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import S01 from './s01';

import S02 from './s02';

import S03 from './s03';

import S04 from './s04';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const { s01, s02, s03, s04 } = content;

        return (
            <Fragment>
                <S01 content={s01} key="s01" />
                <S02 content={s02} key="s02" />
                <div className={style.col_1000}>
                    <Ad content={content.middleAd} />
                </div>
                <S03 content={s03} key="s03" />
                <div className={style.col_1000}>
                    <Ad content={content.s03UnderAd} />
                </div>
                <S04 content={s04} key="s04" />
                <div className={style.adbottom}>
                    <Ad content={content.s04UnderAd} />
                </div>
            </Fragment>
        );
    }
}

export default Content;
