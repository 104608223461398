import React from 'react';
import PropTypes from 'prop-types';

import Loadable from 'react-loadable';

import LazyLoad from '@ifeng-fe/ui_pc_lazyLoad';

const LoadVideoPlayer = Loadable({
    loader: () => import('ThreeTerminal_pc').then(({ Player }) => Player),
    loading() {
        return <div />;
    },
});

// import { MyVideo } from '@ifeng-fe/ui_pc_video';

// const LoadaMyVideo = Loadable({
//     loader: () => import('@ifeng-fe/ui_pc_video').then(({ MyVideo }) => MyVideo),
//     loading() {
//         return <div>Loading...</div>;
//     },
// });

import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/utils';

class VideoDom extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    box = React.createRef();

    async componentDidMount() {
        try {
            const { content } = this.props;
            const { ad } = content;

            console.log('ad=', ad);
            // const ad = {
            //     data: {},
            //     preload: '',
            //     callback: 'function(data,el){alert("22102"); console.log(111, el);el.innerHTML = "fdsafsa"}',
            // };

            const callback = await handleAd(ad);

            callback(ad.data, this.box.current);
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        // 播放器信息
        const playermsg = data => {
            return {
                width: 310,
                height: 232,
                isAuth: 'auth',
                autoPlay: false,
                name: data.title,
            };
        };

        const videoData = content && content.data ? content.data : content;

        return (
            <div ref={this.box}>
                <LazyLoad>
                    <LoadVideoPlayer vid={videoData.guid} videoConfig={videoData} playermsg={playermsg(videoData)} />
                </LazyLoad>
            </div>
        );
    }
}

export default VideoDom;
