import React from 'react';
import PropTypes from 'prop-types';

import { rel } from '@ifeng-fe/ui_rel';
import style from './index.css';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    state = {
        current: false,
    };

    changeShow = index => {
        this.setState({
            current: index,
        });
    };
    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const { current } = this.state;

        const innerDom = content.map((item, i) => {
            return (
                <li key={`dress-${i}`} onMouseOver={() => this.changeShow(i)}>
                    <div className={style.boxT} style={current === i ? { display: 'none' } : { display: 'block' }}>
                        <span>微服私房</span>
                        <p>
                            {/* <strong>vol.78</strong> */}
                            {item.title2 || item.title}
                        </p>
                    </div>
                    <div className={style.boxB} style={current !== i ? { display: 'none' } : { display: 'block' }}>
                        <div className={style.pic}>
                            <a href={item.url} target="_blank" rel={rel}>
                                <img src={item.thumbnails} />
                            </a>
                        </div>
                        <div className={style.tt}>
                            <a href={item.url} target="_blank" rel={rel}>
                                微服私房
                            </a>
                        </div>
                        <div className={style.textBg} />
                        <div className={style.text}>
                            <a href={item.url} target="_blank" rel={rel}>
                                {/* <strong>vol.78</strong> */}
                                {item.title2 || item.title}
                            </a>
                        </div>
                    </div>
                </li>
            );
        });

        return <ul className={style.list09}>{innerDom}</ul>;
    }
}

export default Content;
