import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import style from './index.css';

import TxtTitle from '../../../../../components/txtTitle';
import Static from './static';

class NewProduct extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const config = {
            title: content.title,
            link: content.link,
        };

        return (
            <div className={style.wrap}>
                <TxtTitle content={config} />
                <NoSSR>
                    <Chip
                        id="25035"
                        type="static"
                        title="新品试用"
                        groupName="第三个通栏"
                        translate={'handleS03RightNewProduct'}
                        content={content.data}>
                        <Static />
                    </Chip>
                </NoSSR>
            </div>
        );
    }
}

export default NewProduct;
