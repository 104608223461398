import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

import errorBoundary from '@ifeng-fe/errorBoundary';

import { rel } from '@ifeng-fe/ui_rel';

const tagArray = ['凤凰时尚', '时髦尖货', '表白星饰'];

class TxtPic extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        tagConfig: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content, tagConfig } = this.props;

        const dom = content.map((item, index) => (
            <li key={index}>
                <h3>
                    <a href={item.url} target="_blank" rel={rel}>
                        {item.title}
                    </a>
                </h3>
                <div className={`${style.pictxt} clearfix`}>
                    <div className={style.pic}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <img src={item.pic} title={item.title} className={style.trans} />
                        </a>
                    </div>
                    <div className={style.txt}>{item.summary}</div>
                    <div className={style.b_qian}>
                        <a href={item.url} target="_blank" rel={rel}>
                            {tagConfig[index] || ''}
                        </a>
                    </div>
                </div>
            </li>
        ));

        return <div className={style.list03}>{dom}</div>;
    }
}
export default errorBoundary(TxtPic);
