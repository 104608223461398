import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
// import Ad from '@ifeng-fe/ui_pc_ad';
import Footer from '@ifeng-fe/container_channel/common/client/summer/components/pc/darkFooter';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 Footer 组件
 */
class BottomFooter extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        // const { bottomAd } = content;

        // const BottomAd = (
        //     <div key="bottomAd" className={styles.ad}>
        //         <Ad content={bottomAd} styleName={styles.box} />
        //     </div>
        // );
        const footer = (
            <div key="footer" className={styles.footerbox}>
                <Footer content={content.footer} theme={'fashionIndexDarkFooter'} />
            </div>
        );

        return footer;
        // return [BottomAd, footer];
    }
}

export default errorBoundary(BottomFooter);
