import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ChannelTitle from '../channelTitle';
import ThreePics from '../threePics';
import NewsItems from '../newsItems';
import MoreBtn from '../moreBtn';

class NewsList extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        /**
         * @desc content
         * {
            titleAd:   title冠名广告
            threePic:   第一条是三小图新闻 1条
            list:   列表新闻 3 条
            listAd: 最后一条是广告 1 条 
            ad: 下面加个硬广  1 条
        }
         */

        const channelTitleData = {
            title: content.title,
            titleLink: content.titleLink,
            ad: content.titleAd,
            style: content.style,
        };

        const threePicsData = content.threePic;

        const newsItemsData = {
            list: content.list,
            ad: content.listAd,
        };

        const moreBtnData = {
            link: content.titleLink,
        };

        return (
            <Fragment>
                <ChannelTitle content={channelTitleData} />

                <ThreePics content={threePicsData} />

                <NewsItems content={newsItemsData} />

                <MoreBtn content={moreBtnData} />
            </Fragment>
        );
    }
}

export default errorBoundary(NewsList);
