import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import { rel } from '@ifeng-fe/ui_rel';
import style from './index.css';

import errorBoundary from '@ifeng-fe/errorBoundary';

import Pics from './pics';

class S04 extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return (
            <div className={style.wrap}>
                <div className={style.tit01}>
                    <div className={style.t_name}>
                        <span className={style.s01}>
                            <a href="#" target="_blank" rel={rel}>
                                时尚
                            </a>
                        </span>
                        <span className={style.s02}>
                            <a href="#" target="_blank" rel={rel}>
                                美图
                            </a>
                        </span>
                    </div>
                </div>

                <Chip
                    id="55029"
                    type="recommend"
                    title="时尚美图"
                    groupName="第四个通栏"
                    translate={'handles04Pics'}
                    content={content}>
                    <Pics />
                </Chip>
            </div>
        );
    }
}
export default errorBoundary(S04);
