import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/ui_rel';

import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/utils';

class Li extends React.PureComponent {
    static propTypes = {
        index: PropTypes.number,
        content: PropTypes.object,
        ad: PropTypes.object,
    };

    box = React.createRef();

    async componentDidMount() {
        try {
            const { ad } = this.props;
            const callback = await handleAd(ad);

            callback(ad.data, this.box.current);
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * 渲染组件
     */
    render() {
        const { content, index } = this.props;

        return (
            <li ref={this.box}>
                <span>{index + 1}.</span>
                <a href={content.url} target="_blank" rel={rel}>
                    {content.title}
                </a>
            </li>
        );
    }
}

export default errorBoundary(Li);
