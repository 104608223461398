import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/utils';

import errorBoundary from '@ifeng-fe/errorBoundary';

import Slides from '@ifeng-fe/ui_pc_slides';

import { rel } from '@ifeng-fe/ui_rel';

class Slider extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,

        ad: PropTypes.object,
    };
    state = {
        current: 0,
        data: [],
    };

    swtichPage = (cur, total) => {
        this.setState({
            current: cur,
            total,
        });
    };
    insert = (insertArr, replaceArr, adFn) => {
        const { content } = this.props;

        if (adFn && typeof adFn === 'function') {
            const infoData = [...content];

            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        try {
            const { content } = this.props;

            this.setState({
                data: [...content],
            });

            // @todo : 模拟广告
            // const ad = {
            //     data: {},
            //     preload: '',
            //     callback:
            //         'function(data,insert){console.log(data,insert);var box1=document.createElement("div"); insert([],[2,5],function(dom){dom[2].appendChild(box1)})}',
            // };

            const { ad } = this.props;
            const callback = await handleAd(ad);

            callback(ad.data, this.insert);
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const { current, data } = this.state;

        const sliderTxtTmpl = data => {
            return data.type !== 'ad' ? (
                <div className={style.s_ban_txt}>
                    <div className={style.textBg}>&nbsp;</div>
                    <div className={style.text_swiper}>
                        <h2>
                            <a href={data.url} target="_blank" data-innermask>
                                {data.title}
                            </a>
                        </h2>
                        <p>{data.summary}</p>
                    </div>
                </div>
            ) : null;
        };

        const sliderTmpl = item => {
            return item.type && item.type === 'ad' ? (
                <div className={style.li}>
                    <div ref={item.ref} />
                </div>
            ) : (
                <div className={style.li}>
                    <a className={style.blockA} href={item.url} target="_blank" rel={rel} data-nomask>
                        <img src={item.thumbnail} />
                        <span className={style.bg} />
                    </a>
                </div>
            );
        };

        const config = {
            autoplay: true,
            arrows: true,
            dots: true,
            axis: 'horizonta',
            beginChange: this.swtichPage,
            dotStyle: style.dot,
            dotCurrentStyle: style.current,
            sliderTmpl,
            speed: 0,
            dotsAction: 'hover',
        };

        const dom = (
            <div className={style.s_ban}>
                <div className={style.s_ban_main}>
                    {data.length > 0 ? <Slides content={data} config={config} /> : null}
                    {data[current] ? sliderTxtTmpl(data[current]) : ''}
                </div>
            </div>
        );

        return data && data.length > 0 ? dom : <div />;
    }
}
export default errorBoundary(Slider);
