import React from 'react';
import PropTypes from 'prop-types';

import Slides from '@ifeng-fe/ui_pc_slides';

import { rel } from '@ifeng-fe/ui_rel';
import style from './index.css';

class Slider extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const sliderTmpl = item => (
            <div className={style.pt3}>
                <div className={style.pic}>
                    <a href={item.url} target="_blank" rel={rel}>
                        <img src={item.thumbnail} title={item.title} alt={item.title} className={style.trans} />
                    </a>
                </div>
                <div className={style.text}>
                    <span>{item.summary || ' '}</span>
                    <a href={item.url} target="_blank" rel={rel}>
                        {item.title}
                    </a>
                </div>
            </div>
        );

        const __config = {
            autoplay: true,
            arrows: false,
            dots: true,
            axis: 'horizonta',
            dotStyle: style.dot,
            dotCurrentStyle: style.current,
            sliderTmpl,
            speed: 0,
            dotsAction: 'hover',
            autoplayInterval: 5000,
        };

        return (
            <div className={style.scroll3}>
                {content.length > 0 ? <Slides content={content} config={__config} /> : null}
            </div>
        );
    }
}

export default Slider;
