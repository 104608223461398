import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

import errorBoundary from '@ifeng-fe/errorBoundary';

import { rel } from '@ifeng-fe/ui_rel';

class Pics extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };
    state = {};
    changeShow = (target, type) => {
        const result = {};

        result[target] = type;

        this.setState(result);
    };
    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const data01 = content.slice(0, 2);
        const data02 = content.slice(2, 3);
        const data03 = content.slice(3, 6);
        const data04 = content.slice(6, 8);

        const hiddenTitle = item => {
            return (
                <Fragment>
                    <div className={style.textBg} />
                    <div className={style.text}>
                        <a href={item.url} target="_blank" rel={rel}>
                            {item.title}
                        </a>
                    </div>
                </Fragment>
            );
        };

        const side01 = (data, right) => {
            return (
                <div className={!right ? `${style.box199} ${style.mr01}` : style.box199}>
                    {data.map((item, i) => {
                        const __class = this.state[item.id] ? style.hoverLi : style.li;

                        return (
                            <div
                                onMouseOver={() => this.changeShow(item.id, true)}
                                onMouseOut={() => this.changeShow(item.id, false)}
                                key={i}
                                className={
                                    i === 0
                                        ? `${style.box0} ${style.box199_2} ${style.mt01} ${__class}`
                                        : `${style.box0} ${style.box199_2} ${__class}`
                                }>
                                <a href={item.url} target="_blank" rel={rel}>
                                    <img
                                        src={item.thumbnail}
                                        title={item.title}
                                        alt={item.title}
                                        className={style.trans}
                                    />
                                </a>
                                {hiddenTitle(item)}
                            </div>
                        );
                    })}
                </div>
            );
        };

        const side02 = data02.map((item, i) => {
            const __class = this.state[item.id] ? style.hoverLi : style.li;

            return (
                <div
                    key={i}
                    className={`${style.box0} ${style.box200} ${style.fl} ${style.mr01} ${__class}`}
                    onMouseOver={() => this.changeShow(item.id, true)}
                    onMouseOut={() => this.changeShow(item.id, false)}>
                    <a href={item.url} target="_blank" rel={rel}>
                        <img src={item.thumbnail} title={item.title} alt={item.title} className={style.trans} />
                    </a>
                    {hiddenTitle(item)}
                </div>
            );
        });

        const side03 = data => {
            const __classD = this.state[data[2].id] ? style.hoverLi : style.li;

            return (
                <div className={`${style.box399} ${style.fl} ${style.mr01}`}>
                    <div className={style.clearfix}>
                        {data.slice(0, 2).map((item, i) => {
                            const __class = this.state[item.id] ? style.hoverLi : style.li;

                            return (
                                <div
                                    onMouseOver={() => this.changeShow(item.id, true)}
                                    onMouseOut={() => this.changeShow(item.id, false)}
                                    key={i}
                                    className={`${style.box0} ${style.box199_2} ${style.fl} ${style.mr01} ${__class}`}
                                    style={i === 1 ? { marginRight: 0 } : {}}>
                                    <a href={item.url} target="_blank" rel={rel}>
                                        <img
                                            src={item.thumbnail}
                                            title={item.title}
                                            alt={item.title}
                                            className={style.trans}
                                        />
                                    </a>
                                    {hiddenTitle(item)}
                                </div>
                            );
                        })}
                    </div>
                    <div
                        className={`${style.box0} ${style.box399_2} ${style.mt01} ${__classD}`}
                        onMouseOver={() => this.changeShow(data[2].id, true)}
                        onMouseOut={() => this.changeShow(data[2].id, false)}>
                        <a href={data[2].url} target="_blank" rel={rel}>
                            <img
                                src={data[2].thumbnail}
                                title={data[2].title}
                                alt={data[2].title}
                                className={style.trans}
                            />
                        </a>
                        {hiddenTitle(data[2])}
                    </div>
                </div>
            );
        };

        return (
            <div className={style.list13}>
                {side01(data01)}
                {side02}
                {side03(data03)}
                {side01(data04, true)}
            </div>
        );
    }
}
export default errorBoundary(Pics);
