import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/ui_rel';

import Li from './li.jsx';

const adPositionArray = [1, 2];

class Dress extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        info: PropTypes.object,
    };
    state = {
        data: [],
    };

    // async componentDidMount() {
    //     try {
    //         this.setState({
    //             data: this.props.content,
    //         });

    //         console.log('dress==', this.props);
    //         console.log('this.box = ', this.box);

    //         const { info } = this.props;
    //         const callback = await handleAd(info.ad);

    //         callback(info.ad.data, this.box1.current);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    /**
     * 渲染组件
     */
    render() {
        const { info, content } = this.props;

        // const { data } = this.state;
        // {
        //     logo: '',
        //     title: '',
        //     summary: '',
        //     pic: '',
        //     url: '',
        // }

        const arr = [...content];
        const firstItem = arr.shift() || {};

        const listDom = arr.map((item, index) => {
            let result = '';

            if (adPositionArray.includes(index)) {
                const __position = adPositionArray.indexOf(index);
                const __ad = info['ad'][__position];

                result = <Li key={index} ad={__ad} content={item} index={index} />;
            } else {
                result = (
                    <li key={index}>
                        <span>{index + 1}.</span>
                        <a href={item.url} target="_blank" rel={rel} sign-trs-href={item.url}>
                            {item.title}
                        </a>
                    </li>
                );
            }

            return result;
        });

        return content.length > 0 ? (
            <div className={styles.list02}>
                <div className={styles.pic}>
                    <img src={firstItem.pic} />
                </div>
                <div className={styles.t0}>
                    <a href={firstItem.url} target="_blank" rel={rel}>
                        <img src={info.logo} title="时装" alt="时装" />
                    </a>
                </div>
                <h2>
                    <a href={firstItem.url} target="_blank" rel={rel} sign-trs-href={firstItem.url}>
                        {firstItem.title}
                    </a>
                </h2>
                <div className={styles.textBg} />
                <ul id="ad_yw">{listDom}</ul>
            </div>
        ) : null;
    }
}

export default errorBoundary(Dress);
