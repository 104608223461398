import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import Chip from 'Chip';

import NewsList from '../../../../components/newsList';

import ChannelTitle from '../../../../components/channelTitle';

import BrandCard from './brandCard';

const newsListData = {
    fashion: {
        title: ['时装', '潮流'],
        titleLink: '//fashion.ifeng.com/trends/',
        style: 't_name1',
    },

    beauty: {
        title: ['美容', '美体'],
        titleLink: '//fashion.ifeng.com/beauty/',
        style: 't_name2',
    },
    jewelry: {
        title: ['珠宝', '腕表'],
        titleLink: '//fashion.ifeng.com/luxury/',
        style: 't_name3',
    },
    emotion: {
        title: ['情感', '心理'],
        titleLink: '//fashion.ifeng.com/emotion/',
        style: 't_name4',
    },
    brand: {
        title: ['品牌', '活动'],
        titleLink: '#',
        style: 't_name5',
    },
};

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    getData = key => {
        const { content } = this.props;

        const ori = content[key] || {};
        const config = newsListData[key] || {};

        return {
            ...ori,
            ...config,
        };
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const { brand } = content;

        const fashionData = this.getData('fashion');

        const beautyData = this.getData('beauty');

        const jewelryData = this.getData('jewelry');

        const emotionData = this.getData('emotion');

        const brandTitleData = newsListData['brand'];

        const brandchannelTitleData = {
            title: brandTitleData.title,
            titleLink: brandTitleData.titleLink,
            style: brandTitleData.style,
            ad: brand.titleAd,
        };

        return (
            <div className={style.col}>
                <NewsList content={fashionData} />
                <div className={style.adbox}>
                    <Ad content={content.fashion.ad} />
                </div>
                <div className={style.box650}>
                    <NewsList content={beautyData} />
                </div>
                <div className={style.adbox}>
                    <Ad content={content.beauty.ad} />
                </div>

                <div className={style.box650}>
                    <NewsList content={jewelryData} />
                </div>
                <div className={style.adbox}>
                    <Ad content={content.jewelry.ad} />
                </div>

                <div className={style.box650}>
                    <NewsList content={emotionData} />
                </div>
                <div className={style.adbox}>
                    <Ad content={content.emotion.ad} />
                </div>
                <div className={style.box650}>
                    <ChannelTitle content={brandchannelTitleData} />

                    <Chip
                        key="chip-55028"
                        id="55028"
                        type="recommend"
                        title="品牌活动推荐位"
                        groupName="第三个通栏"
                        translate={'handleS03LeftBrand'}
                        content={content.brand.list}>
                        <BrandCard />
                    </Chip>
                </div>
            </div>
        );
    }
}

export default Content;
